import React from 'react';
import StarRating from './StarRating/StarRating';
import {Field } from 'formik';


const RatingBox = ({rating, changeRating, className, names}) => {
  const myProps = {
    rating:rating, 
    changeRating:changeRating
  }

    return (
      <>
        <table className="manufacRatingTable">
          <tbody>
            <StarRating label="GESAMT*" name={names.ratingTotal} {...myProps} className={className} />
            <StarRating label="Qualität" name={names.ratingQuality} {...myProps}/>
            <StarRating label="Kommunikation" name={names.ratingCommunication} {...myProps}/>
            <StarRating label="Preis/Leistung" name={names.ratingPrice} {...myProps}/>
          </tbody>
        </table>  
        <Field type="hidden" name={names.ratingTotal} className={className} />
        <Field type="hidden" name={names.ratingQuality} />
        <Field type="hidden" name={names.ratingCommunication} />
        <Field type="hidden" name={names.ratingPrice} />
      </>
    );
}

export default RatingBox;