import React from 'react';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';
import * as style from './LabelTooltip.module.css';
import InfoButton from '../../UI/InfoButton/InfoButton';

const LabelToolTip = ({text, className}) => (
  <span className={style.LabelTooltip}>
    <Tooltip 
      trigger={['click', 'hover']} 
      overlay={<span>{text} </span>}
      placement="right"
    > 
      <span><InfoButton color='grey'></InfoButton></span>
    </Tooltip>
  </span>
);



export default LabelToolTip;