import React from "react"
import { PageStyle } from '../../styles/erfahrungVerfassen.module.scss';
import { Formik, Form, Field } from "formik"
import Layout from "../../components/Layout/Layout"
import FormHandler from "../../components/FormHandler/FormHandler"
import FieldInfo from "../../components/Formular/FieldInfo/FieldInfo"
import RatingBox from "../../components/Formular/RatingBox/RatingBox"
import TextareaAutosize from 'react-textarea-autosize';
import Button from "../../components/UI/Button/Button";
import LabelToolTip from "../../components/Formular/LabelTooltip/LabelTooltip";
import Seo from "../../components/SEO/SEO";

class LeaveFeedback extends React.Component {
  state = {
    rating: { total: 0, quality: 0, communication: 0, price: 0 },
    reportLength: 0,
  };
  reportMinLength = 450;
  reportInput = React.createRef();

  changeRating = (newRating, name, setFieldValue) => {
    this.setState(prevState => {
      return { rating: { ...prevState.rating, [name]: newRating } }
    })
    setFieldValue(`${name}`, newRating)
  }

  beforeUnloadHandler = e => {
    // prevent window close if user has already entered some text
    if (this.reportInput.current.value.length > 200) {
      e.preventDefault()
      e.returnValue =
        "Willst du die Seite wirklich verlassen? Mögliche Eingaben gehen verloren."
    }
  }
  reportValidation = values => {
    if (values.report.length < this.reportMinLength) {
      return 'report';
    } else {
      return false;
    }
  }

  reportOnChangHandler = (e, setFieldValue) => {
    this.setState({ reportLength: e.target.value.length });
    setFieldValue("report", e.target.value);
  }

  componentDidMount() {
    window.addEventListener("beforeunload", this.beforeUnloadHandler)
  }
  // componentDidUpdate() {
  //   console.log("did update erfahrung verfassen")
  // }
  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.beforeUnloadHandler)
  }

  render() {
    const { props } = this

    const manufacSelected = props.location.state && props.location.state.manufacName ? true : false;
    const manufacName = manufacSelected ? props.location.state.manufacName : "einen Hersteller";
    const eMailTooltip =
      <LabelToolTip
        text="Wird für den Bestätigungslink benötigt und nicht veröffentlicht."
      />
    const nameTooltip =
      <LabelToolTip
        text={"Du kannst auch nur deinen Vornamen oder ein beliebiges Pseudonym " +
          "eingeben um vollkommen anonym zu bleiben"}
      />
    // const purchaseDateTooltip = 
    //   <LabelToolTip 
    //     text={"Ungefährer Zeitpunkt deines Kaufes oder deiner Bestellung"+
    //           "beim Händler"}
    //   />

    return (
      <>
        <Layout
          headlines={["Deine Erfahrung"]}
          className="centeredPage--TextPage"
        >
          <h1>
            Erfahrungsbericht für <strong>{manufacName}</strong> verfassen
          </h1>
          <FormHandler
            sendTo="php/add.php"
            requiredInputs={[
              "manufacName",
              "email",
              "name",
              "headline",
              "report",
              "rating-total",
              "order",
              "dsgvo",
            ]}
            // rating={this.state.rating}
            additionalValidation={this.reportValidation}
          >
            {({ formikAttr, FHstyle, state, setClassDynamically }, requiredInputs) => (

              // const FieldInfo = React.cloneElement(Field, )
              <Formik
                {...formikAttr}
                initialValues={{
                  manufacName: manufacSelected ? manufacName : "",
                  email: "",
                  name: "",
                  headline: "",
                  report: "",
                  order: "thow",
                  purchaseDate: "",
                  "rating-total": this.state.rating.total,
                  "rating-quality": this.state.rating.quality,
                  "rating-communication": this.state.rating.communication,
                  "rating-price": this.state.rating.price,
                  recommended: "pick",
                }}
              >
                {({ isSubmitting, setFieldValue }) => (
                  // setFieldValue("rating-total", this.state.rating.total)

                  <Form
                    className={[FHstyle.Form, manufacSelected ? FHstyle.ManufacSelected : ""].join(' ')}
                    encType="multipart/form-data"
                  >
                    <fieldset
                      disabled={state.sendingData}
                      style={{
                        display: (state.dataSendSuccess ? "none" : "inherit"),
                        opacity: (state.sendingData ? "0.5" : "initial")
                      }}
                    >
                      <FieldInfo
                        // headline="Welchen Hersteller möchtest du bewerten?"
                        label={{
                          for: "manufacName",
                          text: <>Name des Herstellers{/*<span>, den du bewerten möchtest</span>*/}</>
                        }}
                        hidden={manufacSelected && true}
                        requiredInputs={requiredInputs}
                        className={FHstyle.hasFloatLabel}
                      >
                        <Field
                          // autoFocus={!manufacSelected && true}
                          className={setClassDynamically("manufacName")}
                          type="text"
                          name="manufacName"
                          placeholder=" "
                        />
                      </FieldInfo>

                      <FieldInfo
                        // headline="Ein paar Angaben über dich"
                        label={{
                          for: "name",
                          text: <>Dein Name {nameTooltip}</>
                        }}
                        requiredInputs={requiredInputs}
                        className={[FHstyle.hasFloatLabel, FHstyle.HalfSized].join(' ')}
                      >
                        <Field
                          // autoFocus={manufacSelected && true}
                          className={setClassDynamically("name")}
                          type="text"
                          name="name"
                          placeholder=" "
                        />
                      </FieldInfo>

                      <FieldInfo
                        label={{
                          for: "email",
                          text: <>Deine E-Mail-Adresse {eMailTooltip}</>
                        }}
                        requiredInputs={requiredInputs}
                        className={[FHstyle.hasFloatLabel, FHstyle.HalfSized].join(' ')}
                      >
                        <Field
                          className={setClassDynamically("email")}
                          type="email"
                          name="email"
                          placeholder=" "
                        />
                      </FieldInfo>

                      <FieldInfo
                        label={{
                          for: "order",
                          text: <>Gekauftes Produkt</>,
                          // text: "",
                        }}
                        requiredInputs={requiredInputs}
                        className={[FHstyle.hasFloatLabel, FHstyle.selectWrapper, FHstyle.HalfSized].join(' ')}
                      >
                        <Field
                          className={setClassDynamically("order", "select")}
                          name="order"
                          component="select"
                          placeholder="Deine Bestellung beim Händler"
                        >
                          {/* <option disabled value="pick">
                        Deine Bestellung beim Händler
                        </option> */}
                          <option value="thow">Tiny House mit Rädern</option>
                          <option value="thww">Tiny House ohne Räder</option>
                          <option value="mini">Minihaus</option>
                          <option value="modulhouse">Modulhaus</option>
                          <option value="trailer">Anhänger</option>

                          <option value="Sonstiges">Sonstiges</option>
                        </Field>
                      </FieldInfo>

                      <FieldInfo
                        label={{
                          for: "purchaseDate",
                          text: <>Kauf- bzw. Bestelldatum </>
                        }}
                        requiredInputs={requiredInputs}
                        className={[FHstyle.hasFloatLabel, FHstyle.HalfSized].join(' ')}
                      >
                        <Field
                          className={setClassDynamically("purchaseDate")}
                          type="date"
                          name="purchaseDate"
                          placeholder=" "
                        />
                      </FieldInfo>

                      <FieldInfo
                        headline="Wie würdest du deine Erfahrung bewerten?"
                        requiredInputs={requiredInputs}
                        className={FHstyle.RatingBox}
                      >
                        <RatingBox
                          rating={this.state.rating}
                          changeRating={(newRating, name) => {
                            this.changeRating(newRating, name, setFieldValue)
                          }}
                          className={setClassDynamically("rating-total", "hidden")}
                          names={{
                            ratingTotal: "rating-total",
                            ratingQuality: "rating-quality",
                            ratingCommunication: "rating-communication",
                            ratingPrice: "rating-price",
                          }}
                        />
                      </FieldInfo>

                      <FieldInfo
                        label={{
                          for: "recommended",
                          text: "Würdest du den Hersteller weiterempfehlen?",
                        }}
                        requiredInputs={requiredInputs}
                        className={[FHstyle.hasFloatLabel].join(' ')}
                      >
                        <div className={FHstyle.RadioWrapper}>
                          <Field
                            className={setClassDynamically("recommended", "radio")}
                            type="radio"
                            name="recommended"
                            value="yes"
                          >
                          </Field>
                          <span>ja</span>

                          <Field
                            className={setClassDynamically("recommended", "radio")}
                            type="radio"
                            name="recommended"
                            value="no"
                          >
                          </Field>
                          <span>nein</span>
                        </div>
                      </FieldInfo>


                      <FieldInfo
                        headline="Erzähl uns von deiner Erfahrung"
                        label={{
                          for: "headline",
                          text: <>Titel <span>deines Erfahrungsberichtes</span></>
                        }}
                        requiredInputs={requiredInputs}
                        className={FHstyle.hasFloatLabel}
                      >
                        <Field
                          className={setClassDynamically("headline")}
                          type="text"
                          name="headline"
                          placeholder=" "
                        />
                      </FieldInfo>

                      <FieldInfo
                        label={{
                          for: "report",
                          text: <>Erfahrungsbericht <span>(bitte beschreibe deine Erfahrung ausführlich)</span></>
                        }}
                        requiredInputs={requiredInputs}
                        className={[FHstyle.hasFloatLabel, PageStyle.ReportWrapper].join(' ')}
                      >
                        <Field
                          name="report"
                          render={({ field }) => (
                            <TextareaAutosize
                              {...field}
                              ref={this.reportInput}
                              className={[setClassDynamically("report", "textarea", this), PageStyle.Report].join(' ')}
                              placeholder=" "
                              onChange={e => {
                                this.reportOnChangHandler(e, setFieldValue)
                              }}
                            />
                          )}
                        />
                        <>
                          Beschreibe beispielsweise, was du dir vom Hersteller
                          anfertigen lassen hast. Wie lange hat es gedauert? War
                          der Hersteller freundlich? Bist du mit dem Ergebnis
                          zufrieden? <span className={setClassDynamically("report", "text")}>
                            <strong>Verwende bitte mindestens {this.reportMinLength} Zeichen
                              (bereits {this.state.reportLength} getippt)</strong></span>.
                        </>
                      </FieldInfo>

                      <FieldInfo
                        headline="Schutz deiner Daten"
                        addMargin
                      >
                        <>
                          <p>
                            {" "}
                            Bitte lies dir unsere <a target="_blank" href='/datenschutz/'>Datenschutzerklärung</a> durch. Dort
                            wird genau beschrieben, wie dein Eintrag bei uns
                            verarbeitet wird.
                          </p>
                          <label className={FHstyle.labelCheckbox}>
                            <Field
                              className={setClassDynamically("dsgvo", "checkbox")}
                              type="checkbox"
                              name="dsgvo"
                            />
                            Ja, ich bin mit dem Inhalt der Datenschutzerklärung
                            (insb. Kapitel "Erfahrungsbericht") einverstanden.
                          </label>
                        </>
                      </FieldInfo>

                      <label className={FHstyle.labelCheckbox}>
                        <Field
                          className={setClassDynamically("commentNoti", "checkbox")}
                          type="checkbox"
                          name="commentNoti"
                        />
                        Ich möchte per E-Mail informiert werden, wenn mein
                        Erfahrungsbericht kommentiert wird.
                      </label>

                      <Button color="black">
                        <button
                          className={setClassDynamically("submit", "button")}
                          type="submit"
                          disabled={isSubmitting}
                        >
                          Senden
                        </button>
                      </Button>
                    </fieldset>
                  </Form>
                )}
              </Formik>
            )}
          </FormHandler>
        </Layout>
      </>
    )
  }
}

export default LeaveFeedback;

export function Head() {
  return (

    <Seo
      title='Erfahrungsbericht für Tiny House Hersteller verfassen | THH'
      meta_description=
      {'Du hast bereits Erfahrungen mit einem Tiny House Hersteller gemacht?' +
        ' Teile deine Erfahrung und hilf anderen damit weiter!'}
    />

  )
}