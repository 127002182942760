import React from 'react';
import StarRatings from 'react-star-ratings';
import *as style from './StarRating.module.scss';
const StarRating = ({rating, changeRating, name, label, className}) => {
  // debugger;
  return (
    <tr className={style.Column}>
      <td className={className}>{label}</td>
      <td className={style.RatingTableCell}>
        <StarRatings
        starDimension="25px"
        rating={rating[name]}
        starRatedColor="rgba(145, 73, 66, 1)"
        starHoverColor="rgba(145, 73, 66, 1)"
        changeRating={changeRating}
        numberOfStars={5}
        name={name}
        starEmptyColor="rgba(255, 255, 255, 0.8)"
      />
      </td>
    </tr>
    
  );
}
// rating={this.state.rating.quality}

export default StarRating;